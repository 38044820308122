import React, { useCallback, useEffect, useState } from 'react';
import {
  DatePicker, Form, Input, message, Modal, Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { ShareAltOutlined } from '@ant-design/icons';
import EyeOutlined from '@ant-design/icons/lib/icons/EyeOutlined';
import { useHistory } from 'react-router';
import { DEFAULT_DATE_TIME, DEFAULT_TIME } from '../../../utils/constants/timeFormats';
import {
  closeShareVehicles,
  mergeUpdateInterfaceFilters,
  ShareVehicleMode,
  submitEditSharing,
  submitShareVehicles,
} from '../../../store/actions';
import { HStretched } from '../../Styled/Containers';
import { InputAffixFormItem, InputWithSelectAfter, SelectWithHiddenTip } from './ShareVehiclesModal.styled';
import { isSuccess } from '../../../utils/responseUtils';
import SharingSuccessContent from './SharingSuccessContent';
import {
  FILTERS, ShareDirection,
} from '../../../modules/Sharing/Components/SharesTable/SharesTableComponent.constants';
import { getShareVehiclesModalData, TableName } from '../../../store/selectors';

const ShareVehiclesModal = () => {
  const {
    loading, vehicles, sharing, visible, fixed, mode, callback,
  } = useSelector(getShareVehiclesModalData);
  const { t } = useTranslation();
  const [selectVehicleOptions, setSelectVehicleOptions] = useState([]);
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    function getEmailValueOrFallback() {
      if (mode === ShareVehicleMode.NEW) {
        return null;
      }

      return sharing.personEmail || t('SHARE_VEHICLES.UNAVAILABLE_EMAIL');
    }

    form.setFields([
      { name: 'vehicleIds', value: sharing.selectedVehicles.map((v) => v.vehicleId) },
      { name: 'emailAddress', value: getEmailValueOrFallback() },
      { name: 'sharingType', value: sharing.ownershipType },
      {
        name: 'startDateTime',
        value: (sharing.useDateFrom && moment.unix(sharing.useDateFrom)) || null,
      },
      {
        name: 'endDateTime',
        value: (sharing.useDateTo && moment.unix(sharing.useDateTo)) || null,
      },
      { name: 'additionalInfo', value: undefined },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sharing, form, t]);

  useEffect(() => {
    const vehiclesById = {};
    [...sharing.selectedVehicles, ...vehicles].forEach((v) => {
      vehiclesById[v.vehicleId] = v;
    });
    setSelectVehicleOptions(Object.values(vehiclesById));
  }, [vehicles, sharing.selectedVehicles]);

  const submitShare = (values) => dispatch(submitShareVehicles(values));

  const submitEdit = (values, editedSharing) => dispatch(
    submitEditSharing(values, editedSharing.createdDate, editedSharing.sharedToCompanyId),
  );

  const submitDependingOnMode = (values, currentMode, currentSharing) => {
    switch (currentMode) {
      case ShareVehicleMode.EDIT:
        return submitEdit(values, currentSharing);
      default:
        return submitShare(values);
    }
  };

  const handleSubmissionResult = (result, currentSharing, onGoToSharing) => {
    if (isSuccess(result)) {
      switch (mode) {
        case ShareVehicleMode.EDIT:
          message.success(t('SHARE_VEHICLES.EDIT_SUCCESS', { vehicleId: currentSharing.vehicleId }), 5);
          break;
        case ShareVehicleMode.NEW:
          message.success(
            <SharingSuccessContent
              goToSharing={onGoToSharing}
              additionalInfo={result.payload.data.additionalInfo}
            />, 5,
          );
          break;
        case ShareVehicleMode.RESTORE:
          message.success(t('SHARE_VEHICLES.RESTORE_SUCCESS', { vehicleId: currentSharing.vehicleId }), 5);
          break;
        default:
          break;
      }
    }
    if (callback) {
      callback(result);
    }
  };

  const handleOk = useCallback(() => {
    function goToSharing() {
      dispatch(mergeUpdateInterfaceFilters(TableName.Sharings, { [FILTERS.SIDE.name]: [ShareDirection.OUT] }));
      history.push({ pathname: '/sharings' });
    }

    form.validateFields()
      .then((values) => submitDependingOnMode(values, mode, sharing))
      .then((result) => {
        handleSubmissionResult(result, sharing, goToSharing);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history, form, mode, sharing]);

  const handleCancel = useCallback(() => {
    form.resetFields();
    dispatch(closeShareVehicles());
  }, [form, dispatch]);

  const disabledDate = (current) => current && current < moment().startOf('d');

  const sharingTypeOptions = [
    {
      value: 'LEASE',
      label: (
        <span>
          <ShareAltOutlined />
          <span className="tip">
            {t('DICTIONARY.STATUS.LEASE')}
          </span>
        </span>
      ),
    },
    {
      value: 'SHARING',
      label: (
        <span>
          <EyeOutlined />
          <span className="tip">
            {t('DICTIONARY.STATUS.SHARING')}
          </span>
        </span>
      ),
    },
  ];

  const pickSharingType = (
    <InputAffixFormItem name="sharingType">
      <SelectWithHiddenTip
        dropdownMatchSelectWidth={120}
        options={sharingTypeOptions}
      />
    </InputAffixFormItem>
  );

  const endDateValidator = (_, date) => {
    if (date) {
      const startDateTimeField = form.getFieldValue('startDateTime');
      const endDateTime = date.clone().startOf('minute');
      if (startDateTimeField) {
        const startDateTime = startDateTimeField.clone().startOf('minute');
        if (endDateTime.isBefore(startDateTime)) {
          return Promise.reject();
        }
      }
    }
    return Promise.resolve();
  };

  const startDateValidator = (_, date) => {
    if (date) {
      const startTime = date.clone().startOf('minute');
      if (startTime) {
        if (startTime.isBefore(moment())) {
          return Promise.reject();
        }
      }
    }
    return Promise.resolve();
  };

  const validateEnd = () => {
    form.validateFields(['endDateTime']);
  };

  const onStartDateChange = (value) => {
    form.setFieldsValue({
      startDateTime: value,
    });
    validateEnd(form);
  };

  const onEndDateChange = (value) => {
    form.setFieldsValue({
      endDateTime: value,
    });
    validateEnd(form);
  };

  return (
    <Modal
      destroyOnClose
      open={visible}
      title={t('VEHICLE.SHARE_VEHICLES')}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
      width={500}
      okText={t(mode === ShareVehicleMode.EDIT ? 'COMMON.EDIT' : 'COMMON.SHARE')}
    >
      <Form
        form={form}
        layout="vertical"
        name="generateReport"
      >
        <Form.Item
          name="vehicleIds"
          label={t('SHARE_VEHICLES.VEHICLES')}
          rules={[{
            required: true,
            message: t('SHARE_VEHICLES.PLEASE_SELECT_VEHICLES'),
          }]}
        >
          <Select
            showSearch
            mode="multiple"
            optionFilterProp="children"
            disabled={fixed || mode === ShareVehicleMode.EDIT}
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {selectVehicleOptions.map(({ vehicleId, licencePlateNumber }) => (
              <Select.Option
                value={vehicleId}
                key={vehicleId}
              >
                {licencePlateNumber}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          name="emailAddress"
          label={t('SHARE_VEHICLES.EMAIL')}
          rules={[
            {
              required: mode === ShareVehicleMode.NEW,
              type: mode === ShareVehicleMode.NEW ? 'email' : undefined,
              message: t('SHARE_VEHICLES.PLEASE_PROVIDE_EMAIL'),
            },
          ]}
        >
          <InputWithSelectAfter
            disabled={mode !== ShareVehicleMode.NEW}
            addonAfter={pickSharingType}
          />
        </Form.Item>
        <HStretched _justify="flex-start">
          <Form.Item
            label={t('ORDER.START_DATE')}
            name="startDateTime"
            style={{ width: '100%', marginRight: '8px' }}
            rules={[{
              required: true,
              message: t('ORDER.START_DATE_REQUIRED'),
            }, {
              validator: startDateValidator,
              message: t('ORDER.START_DATE_MUST_BE_IN_THE_FUTURE'),
            }]}
          >
            <DatePicker
              placeholder=""
              onSelect={onStartDateChange}
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              showTime={{
                format: DEFAULT_TIME,
              }}
              format={DEFAULT_DATE_TIME}
            />
          </Form.Item>
          <Form.Item
            label={t('ORDER.END_DATE')}
            name="endDateTime"
            style={{ width: '100%', marginLeft: '8px' }}
            rules={[{
              required: true,
              message: t('ORDER.END_DATE_REQUIRED'),
            }, {
              validator: endDateValidator,
              message: t('ORDER.END_DATE_MUST_NOT_AFTER_START'),
            }]}
          >
            <DatePicker
              placeholder=""
              onSelect={onEndDateChange}
              style={{ width: '100%' }}
              disabledDate={disabledDate}
              showTime={{
                format: DEFAULT_TIME,
              }}
              format={DEFAULT_DATE_TIME}
            />
          </Form.Item>
        </HStretched>
        <Form.Item
          name="additionalInfo"
          label={t('SHARE_VEHICLES.ADDITIONAL_INFO')}
          rules={[{
            type: 'string',
            max: 2500,
            message: t('SHARE_VEHICLES.ADDITIONAL_INFO_MAX_LENGTH_VIOLATION'),
          }]}
        >
          <Input.TextArea showCount maxLength={2500} autoSize={{ minRows: 4, maxRows: 10 }} />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default ShareVehiclesModal;
