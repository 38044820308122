import { QueryMapper } from '../../../store/actions/axiosHelpers';

export const FILTERS = {
  CREATED_DATE: {
    name: 'CREATED_DATE',
    apiProperty: 'createdDate',
    queryMapper: [QueryMapper.takeFirst, QueryMapper.toEndOfDay, QueryMapper.toTimestamp],
  },
  ORDER_NUMBER: {
    name: 'ORDER_NUMBER',
    apiProperty: 'orderNumber',
    queryMapper: [QueryMapper.takeFirst],
  },
  LICENCE_PLATE: {
    name: 'LICENCE_PLATE',
    apiProperty: 'licensePlate',
    queryMapper: [QueryMapper.takeFirst],
  },
  VEHICLE: {
    name: 'VEHICLE',
    apiProperty: 'vehicle',
    queryMapper: QueryMapper.takeFirstProperty,
    properties: [
      {
        name: 'LICENSE_PLATE',
        apiProperty: 'licencePlateNumber',
        queryMapper: QueryMapper.takeFirst,
      },
    ],
  },
  CARRIER: {
    name: 'CARRIER',
    apiProperty: 'carrierCompany',
    queryMapper: QueryMapper.takeFirstProperty,
    properties: [
      {
        name: 'CARRIER_NAME',
        apiProperty: 'name',
        queryMapper: QueryMapper.takeFirst,
      },
    ],
  },
  SHIPPER: {
    name: 'SHIPPER',
    apiProperty: 'shipperName',
  },
  ADDITIONAL_INFO: {
    name: 'ADDITIONAL_INFO',
    apiProperty: 'name',
    queryMapper: [QueryMapper.takeFirst],
  },
  INTERNAL_ID: {
    name: 'INTERNAL_ID',
    apiProperty: 'internalIds',
  },
  START_DATE: {
    name: 'START_DATE',
    apiProperty: 'startDateFrom',
    queryMapper: [QueryMapper.takeFirst, QueryMapper.toStartOfDay, QueryMapper.toTimestamp],
  },
  TIME_MONITORING_START_DATE: {
    name: 'TIME_MONITORING_START_DATE',
    apiProperty: 'timeMonitoringStartDate',
    queryMapper: [QueryMapper.takeFirst, QueryMapper.toStartOfDay, QueryMapper.toTimestamp],
  },
  TIME_MONITORING_END_DATE: {
    name: 'TIME_MONITORING_END_DATE',
    apiProperty: 'timeMonitoringEndDate',
    queryMapper: [QueryMapper.takeFirst, QueryMapper.toEndOfDay, QueryMapper.toTimestamp],
  },
  REQUEST_STATE: {
    name: 'REQUEST_STATE',
    apiProperty: 'states',
  },
  END_DATE: {
    name: 'END_DATE',
    apiProperty: 'endDateTo',
    queryMapper: [QueryMapper.takeFirst, QueryMapper.toEndOfDay, QueryMapper.toTimestamp],
  },
  STATUS: {
    name: 'STATUS',
    apiProperty: 'statuses',
  },
};

export const OrdersColumnWidth = {
  transportOrderNumber: 160,
  name: 200,
  company: 190,
  lastLocation: 210,
  licencePlate: 160,
  startDate: 250,
  endDate: 250,
  firstRoutePoint: 175,
  nextRoutePoint: 175,
  createdDate: 130,
  status: 85,
  actions: 100,
};

export const statusOptions = [
  { label: 'PROPERTIES.FUTURE', value: 'FUTURE' },
  { label: 'PROPERTIES.ACTIVE', value: 'ACTIVE' },
  { label: 'PROPERTIES.EXPIRED', value: 'EXPIRED' },
  { label: 'PROPERTIES.INACTIVE', value: 'INACTIVE' },
];
