export const toVehiclesTableProps = ({
  computedId, vehicleId, vehicle, ownershipType, useDateFrom, useDateTo, remark,
}) => {
  const {
    licencePlateNumber, vehicleType, make, vehicleCompany, lastPosition,
  } = vehicle;
  const {
    id, name, country, city, taxNumber, taxCountryCode,
  } = vehicleCompany;
  const {
    coordinateLatitude, coordinateLongitude, ignitionState, speed, serverTimestamp, positionTimestamp,
  } = lastPosition[0] || {};

  return {
    computedId,
    vehicleId,
    ownershipType,
    licencePlateNumber,
    vehicleType,
    make,
    useDateFrom,
    useDateTo,
    remark,
    vehicleCompany: {
      id,
      name,
      country,
      city,
      taxNumber,
      taxCountryCode,
    },
    lastPosition: {
      ignitionState,
      latitude: coordinateLatitude,
      longitude: coordinateLongitude,
      speed,
      serverTimestamp,
      positionTimestamp,
    },
  };
};
