export const COL = {
  SHOW_ON_MAP: 'SHOW_ON_MAP',
  VEHICLE_ID: 'VEHICLE_ID',
  NOTES: 'NOTES',
  STATUS: 'STATUS',
  COMPANY_NAME: 'COMPANY_NAME',
  LAST_LOCATION: 'LAST_LOCATION',
  COORDINATES: 'COORDINATES',
  LAST_UPDATE: 'LAST_UPDATE',
  IGNITION: 'IGNITION',
  SPEED: 'SPEED',
  TYPE: 'TYPE',
  MAKE: 'MAKE',
  ACTIONS: 'ACTIONS',
};

export const COL_WIDTHS = {
  SHOW_ON_MAP: 50,
  VEHICLE_ID: 120,
  NOTES: 120,
  STATUS: 70,
  COMPANY_NAME: 250,
  LAST_LOCATION: 170,
  COORDINATES: 165,
  LAST_UPDATE: 145,
  IGNITION: 100,
  SPEED: 90,
  TYPE: 150,
  MAKE: 150,
  ACTIONS: 65,
  SELECTION: 38,
};

export const ALL_COLS = Object.keys(COL);

export const createColToVisibilityMap = (visibleColumns) => {
  const colToVisibility = {};
  ALL_COLS.forEach((col) => {
    colToVisibility[col] = visibleColumns.includes(col);
  });
  return colToVisibility;
};

export const MANDATORY_COLS = [
  COL.VEHICLE_ID,
  COL.ACTIONS,
  COL.SHOW_ON_MAP,
];

export const FILTERS = {
  VEHICLE_ID: {
    name: 'VEHICLE_ID',
    tableFilter: (record, filterValue) => {
      const value = record.licencePlateNumber;
      const filter = filterValue && filterValue.length && filterValue[0];
      return !filter || (value && value.toLowerCase().includes(filter.toLowerCase()));
    },
  },
  MAKE: {
    name: 'MAKE',
    tableFilter: (record, filterValue) => {
      const value = record.make;
      const filter = filterValue && filterValue.length && filterValue[0];
      return !filter || (value && value.toLowerCase().includes(filter.toLowerCase()));
    },
  },
  COMPANY_NAME: {
    name: 'COMPANY_NAME',
    tableFilter: (record, filterValue) => {
      const { name, country, city } = record.vehicleCompany;
      const filter = filterValue && filterValue.length && filterValue[0] && filterValue[0].toLowerCase();
      return !filter
        || (name && name.toLowerCase().includes(filter))
        || (country && country.toLowerCase().includes(filter))
        || (city && city.toLowerCase().includes(filter));
    },
  },
};
