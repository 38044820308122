import React, { useEffect, useState } from 'react';
import {
  Form, Input, message, Modal, Select,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { vehiclesFormToConfiguration } from '../../types/mappers/vehiclesForm.mapper';
import {
  closeNewVehiclesFilter,
  createTableConfiguration,
  updateLastUsedTableConfiguration, updateTableConfiguration,
} from '../../store/actions/personalization';
import { getVehicleFilterModalData, TableName } from '../../store/selectors';
import { isSuccess } from '../../utils/responseUtils';
import FilterCreationSuccessContent from './FilterCreationSuccessContent';

const Mode = {
  Create: 'create',
  Update: 'update',
};

const ModeSettings = {
  [Mode.Create]: {
    title: 'PERSONALIZATION.CREATE_COMPANIES_CONFIGURATION',
  },
  [Mode.Update]: {
    title: 'PERSONALIZATION.EDIT_COMPANIES_CONFIGURATION',
  },
};

const VehiclesFilterModal = () => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const [mode, setMode] = useState(Mode.Create);
  const {
    open, selectedVehicles, editConfiguration, setAsCurrent,
  } = useSelector(getVehicleFilterModalData);
  const dispatch = useDispatch();

  useEffect(() => {
    setMode(editConfiguration ? Mode.Update : Mode.Create);
  }, [editConfiguration]);

  useEffect(() => {
    if (!form) {
      return;
    }

    form.setFields([
      {
        name: 'id',
        value: editConfiguration ? editConfiguration.id : null,
      },
      {
        name: 'name',
        value: editConfiguration ? editConfiguration.name : null,
      },
      {
        name: 'vehicleIds',
        value: selectedVehicles.map(({
          vehicleId, licencePlateNumber,
        }) => ({ value: vehicleId, label: licencePlateNumber })),
      },
    ]);
  }, [form, selectedVehicles, editConfiguration]);

  const dispatchClose = (resetSelection) => {
    dispatch(closeNewVehiclesFilter({ resetSelection }));
  };

  const handleUpdateResult = (result) => {
    if (isSuccess(result)) {
      dispatch(updateLastUsedTableConfiguration(TableName.Fleet, result.payload.data.id));
      dispatchClose(true);
    }
  };

  const handleResult = (result) => {
    if (isSuccess(result)) {
      const { name, id } = result.payload.data;
      message.success(
        <FilterCreationSuccessContent
          name={name}
          mode={mode}
          onSelect={() => setAsCurrent(id)}
        />,
      );
      dispatchClose(true);
    }
  };

  const dispatchSaveConfiguration = (config) => {
    const apiModel = vehiclesFormToConfiguration(config);
    return mode === Mode.Update
      ? dispatch(updateTableConfiguration(TableName.Fleet, apiModel)).then(handleUpdateResult)
      : dispatch(createTableConfiguration(TableName.Fleet, apiModel)).then(handleResult);
  };

  return (
    <Modal
      title={t(ModeSettings[mode].title)}
      open={open}
      okText={t('COMMON.SAVE')}
      cancelText={t('COMMON.BACK')}
      onOk={() => form
        .validateFields()
        .then((values) => dispatchSaveConfiguration(values))}
      onCancel={() => {
        form.resetFields();
        dispatchClose(false);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        name="vehiclesFilter"
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label={t('PERSONALIZATION.CONFIGURATION_NAME')}
          rules={[{
            type: 'string',
            min: 1,
            max: 25,
            message: t('PERSONALIZATION.NAME_SIZE_RESTRICTION'),
          }, {
            required: true,
            message: t('PERSONALIZATION.PLEASE_PROVIDE_NAME'),
          }]}
        >
          <Input placeholder={t('PERSONALIZATION.TYPE_IN_CONFIGURATION_NAME')} />
        </Form.Item>
        <Form.Item
          name="vehicleIds"
          label={t('PERSONALIZATION.VEHICLES')}
          rules={[{
            type: 'array',
            min: 1,
            required: true,
            message: t('PERSONALIZATION.PLEASE_SELECT_VEHICLES'),
          }]}
        >
          <Select
            style={{ width: '100%' }}
            mode="multiple"
            labelInValue
            placeholder={t('PERSONALIZATION.SELECT_VEHICLES_PROMPT')}
            rules={[{
              required: true,
              message: t('PERSONALIZATION.PLEASE_SELECT_VEHICLES'),
            }]}
          >
            {selectedVehicles.map(({ vehicleId, licencePlateNumber }) => (
              <Select.Option key={vehicleId} value={vehicleId}>{licencePlateNumber}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default VehiclesFilterModal;
