/* eslint-disable no-case-declarations */
import { OrderContext } from '../../../../types';
import { ERROR_CODE } from '../../../../utils/apiError';
import {
  FETCH_MONITORING_ORDER_ROUTE_POINTS, FETCH_ORDERS, RESET_COMPANY_CONTEXT, SET_ORDERS_PAGINATION,
} from '../../../actions';

const initState = [];

const isFirstRoutePointRequested = (filter) => !filter || filter === 'FIRST';

const isNextRoutePointRequested = (filter) => !filter || filter === 'NEXT';

const buildUpdatedRoutePoint = (routePoint, loading, error) => ({
  stopId: routePoint?.stopId,
  actualArrival: routePoint?.actualArrival,
  estimatedArrival: routePoint?.estimatedArrival,
  loading,
  error,
});

const resolveFirstRoutePoint = (point, newPoint, loading, error, filter) => (isFirstRoutePointRequested(filter)
  ? buildUpdatedRoutePoint(newPoint || point, loading, error)
  : point);

const resolveNextRoutePoint = (point, newPoint, loading, error, filter) => (isNextRoutePointRequested(filter)
  ? buildUpdatedRoutePoint(newPoint || point, loading, error)
  : point);

const buildUpdatedAsset = (asset, newAsset, loading, error, filter) => ({
  vehicleId: newAsset?.vehicleId || asset.vehicleId,
  firstRoutePoint: resolveFirstRoutePoint(asset.firstRoutePoint, newAsset?.firstRoutePoint, loading, error, filter),
  nextRoutePoint: resolveNextRoutePoint(asset.nextRoutePoint, newAsset?.nextRoutePoint, loading, error, filter),
});

const buildUpdatedRoute = (monitoringOrderId, asset, newAsset, loading, error, filter) => ({
  orderId: monitoringOrderId,
  assetsRoutes: [buildUpdatedAsset(asset, newAsset, loading, error, filter)],
});

const buildUpdatedRouteForSuccessResponse = (monitoringOrderId, newAssets, loading, error, filter) => ({
  orderId: monitoringOrderId,
  assetsRoutes: newAssets.map((asset) => buildUpdatedAsset(asset, asset, loading, error, filter)),
});

const buildNewRoutePoint = (loading, error) => ({
  loading,
  error,
});

const buildNewAsset = (loading, error) => ({
  firstRoutePoint: buildNewRoutePoint(loading, error),
  nextRoutePoint: buildNewRoutePoint(loading, error),
});

const buildNewRoute = (monitoringOrderId, loading, error) => ({
  orderId: monitoringOrderId,
  assetsRoutes: [buildNewAsset(loading, error)],
});

const mapErrorStatus = (status) => {
  switch (status) {
    case 504:
      return ERROR_CODE.ROUTE_POINT_UNABLE_TO_CALCULATE;
    default:
      return null;
  }
};

const setLoading = (routePoint, shouldChange) => {
  if (shouldChange) {
    return routePoint
      ? buildUpdatedRoutePoint(routePoint, true)
      : buildNewRoutePoint(true);
  }
  return routePoint;
};

const handleStart = (action, state) => {
  const { routePointFilter, monitoringOrderId } = action;
  let isNew = true;
  const newRoutes = state.map((route) => {
    if (route.orderId === monitoringOrderId) {
      isNew = false;
      return {
        orderId: monitoringOrderId,
        assetsRoutes: route.assetsRoutes.map((asset) => (
          {
            ...asset,
            firstRoutePoint: setLoading(asset.firstRoutePoint, isFirstRoutePointRequested(routePointFilter)),
            nextRoutePoint: setLoading(asset.nextRoutePoint, isNextRoutePointRequested(routePointFilter)),
          }
        )),
      };
    }
    return route;
  });
  if (isNew) {
    return state.concat(buildNewRoute(monitoringOrderId, true, null));
  }
  return newRoutes;
};

const findMatchingRouteByVehicleId = (previousAssetsRoutes, newAssetRoute) => (
  previousAssetsRoutes.find((route) => route.vehicleId === newAssetRoute.vehicleId)
);

const mergeAssetRoutes = (previousAssetsRoutes, newRoute, routePointFilter) => ({
  orderId: newRoute.orderId,
  assetsRoutes: newRoute.assetsRoutes
    .map((newAssetRoute) => (
      {
        ...newAssetRoute,
        firstRoutePoint:
            isFirstRoutePointRequested(routePointFilter)
              ? newAssetRoute.firstRoutePoint
              : (findMatchingRouteByVehicleId(previousAssetsRoutes, newAssetRoute)?.firstRoutePoint || {}),
        nextRoutePoint:
            isNextRoutePointRequested(routePointFilter)
              ? newAssetRoute.nextRoutePoint
              : (findMatchingRouteByVehicleId(previousAssetsRoutes, newAssetRoute)?.nextRoutePoint || {}),
      }
    ))
    .sort((a, b) => (`${b.vehicleId}`).localeCompare(a.vehicleId)),
});

const handleSuccess = (action, state) => {
  const responseData = action.payload.data;
  const { meta: { previousAction: { routePointFilter } } } = action;
  return state.map((route) => {
    const responseOrderId = responseData.orderId;
    if (route.orderId === responseOrderId) {
      const responseAsset = responseData.assetsRoutes;
      const previousAssetsRoute = route.assetsRoutes;
      // eslint-disable-next-line max-len
      const newRoute = buildUpdatedRouteForSuccessResponse(responseOrderId, responseAsset, false, null, routePointFilter);
      return mergeAssetRoutes(previousAssetsRoute, newRoute, routePointFilter);
    }
    return route;
  });
};

const handleError = (action, state) => {
  const {
    meta: { previousAction: { monitoringOrderId, routePointFilter } },
    error: { response: { data: { errorCode }, status } },
  } = action;
  const error = errorCode || mapErrorStatus(status);
  return state.map((route) => {
    if (route.orderId === monitoringOrderId) {
      const asset = route.assetsRoutes[0];
      return buildUpdatedRoute(monitoringOrderId, asset, null, false, error, routePointFilter);
    }
    return route;
  });
};

export default (orderType = OrderContext.UNKNOWN) => (state = initState, action) => {
  switch (action.type) {
    case FETCH_MONITORING_ORDER_ROUTE_POINTS[orderType].START:
      return handleStart(action, state);
    case FETCH_MONITORING_ORDER_ROUTE_POINTS[orderType].SUCCESS:
      return handleSuccess(action, state);
    case FETCH_MONITORING_ORDER_ROUTE_POINTS[orderType].FAIL:
      return handleError(action, state);
    case FETCH_ORDERS[orderType].START:
      return initState;
    case RESET_COMPANY_CONTEXT:
      return initState;
    case SET_ORDERS_PAGINATION[orderType]:
      return initState;
    default:
      return state;
  }
};
