import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ReloadOutlined from '@ant-design/icons/lib/icons/ReloadOutlined';
import { hereLocationPropType } from '../../../types';
import { CellButton, ParagraphNoBottom } from '../../Styled';
import LatLng from './LatLng';
import DateTime from './DateTime';

const propTypes = {
  hereLocation: hereLocationPropType,
  position: PropTypes.shape({
    lat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    lng: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  }),
  onRefresh: PropTypes.func,
  withCoordinates: PropTypes.bool,
  withTimestamp: PropTypes.bool,
};

const defaultProps = {
  hereLocation: null,
  position: {},
  onRefresh: undefined,
  withCoordinates: false,
  withTimestamp: false,
};

const HereMapsLocation = ({
  hereLocation, position, onRefresh, withCoordinates, withTimestamp,
}) => {
  const { t } = useTranslation();
  const [content, setContent] = useState(t('COMMON.MISSING'));
  const [coordinates, setCoordinates] = useState({});
  const [resultType, setResultType] = useState(undefined);

  useEffect(() => {
    if (hereLocation && hereLocation.items && hereLocation.items.length && hereLocation.items[0].title) {
      const closestAddress = hereLocation.items[0];
      setContent(closestAddress.title);
      setCoordinates(closestAddress.position || {});
      setResultType(closestAddress.resultType);
    }
  }, [hereLocation]);

  return (
    <div>
      {resultType && (
        <ParagraphNoBottom>
          <Typography.Text strong>
            {t('COMMON.LOCATION_TYPE')}
            {': '}
          </Typography.Text>
          <Typography.Text>
            {t(`DICTIONARY.HERE_LOCATION_RESULT_TYPE.${resultType}`)}
          </Typography.Text>
        </ParagraphNoBottom>
      )}
      <Typography.Text>
        {content}
      </Typography.Text>
      {withTimestamp && (
        <DateTime value={position.timestamp} elapsed />
      )}
      {withCoordinates && (
        <LatLng latitude={coordinates.lat} longitude={coordinates.lng} copy hideEmpty />
      )}
      {onRefresh && (
        <CellButton
          type="link"
          size="small"
          icon={<ReloadOutlined />}
          onClick={onRefresh}
        >
          {t('COMMON.REFRESH_LOCATION')}
        </CellButton>
      )}
    </div>
  );
};

HereMapsLocation.propTypes = propTypes;
HereMapsLocation.defaultProps = defaultProps;

export default HereMapsLocation;
