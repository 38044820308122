import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import AimOutlined from '@ant-design/icons/lib/icons/AimOutlined';
import { Skeleton, Typography } from 'antd';
import { CellButton } from '../../Styled';
import HereMapsLocation from './HereMapsLocation';
import { fetchOrderLastPositions } from '../../../store/actions';
import { getVehicleLocation } from '../../../store/selectors';

const propTypes = {
  onError: PropTypes.func,
  vehicleId: PropTypes.string.isRequired,
  shipperId: PropTypes.string.isRequired,
  orderId: PropTypes.string.isRequired,
  licencePlate: PropTypes.string.isRequired,
  vehiclesCount: PropTypes.number.isRequired,
};

const defaultProps = {
  onError: null,
};

const GetOrderVehicleLocation = ({
  onError, vehicleId, shipperId, orderId, licencePlate, vehiclesCount,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const vehicleLocation = useSelector(
    getVehicleLocation(['orders', vehicleId, shipperId, orderId].join('_')),
  );

  const onFetchLocation = useCallback(() => {
    setLoading(true);
    dispatch(fetchOrderLastPositions(vehicleId, shipperId, orderId))
      .catch(() => onError && onError())
      .finally(() => setLoading(false));
  }, [dispatch, vehicleId, shipperId, orderId, onError]);

  const renderGetLocationPrompt = () => (
    <div style={{ overflow: 'auto', display: 'inline-block' }}>
      <CellButton
        type="link"
        icon={<AimOutlined />}
        onClick={(e) => {
          e.stopPropagation();
          onFetchLocation();
        }}
      >
        {vehiclesCount <= 1 && t('COMMON.GET_LOCATION') }
        {vehiclesCount > 1 && `${t('COMMON.GET_LOCATION')} for ${licencePlate}`}
      </CellButton>
    </div>
  );

  return (
    <Skeleton
      loading={loading}
      active
      title={false}
      paragraph={{
        rows: 3,
        width: [100, 180, 140],
      }}
    >
      {vehicleLocation && vehicleLocation.location ? (
        <div>
          {vehiclesCount > 1 && (
            <Typography.Text>
              {licencePlate}
            </Typography.Text>
          )}
          <HereMapsLocation
            hereLocation={vehicleLocation.location}
            onRefresh={onFetchLocation}
            position={vehicleLocation.coordinates}
            withCoordinates
            withTimestamp
          />
        </div>
      ) : renderGetLocationPrompt()}
    </Skeleton>
  );
};

GetOrderVehicleLocation.propTypes = propTypes;
GetOrderVehicleLocation.defaultProps = defaultProps;

export default GetOrderVehicleLocation;
