import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, message, Modal, Form, Radio,
} from 'antd';
import { useHistory } from 'react-router-dom';
import {
  getGeofencing, getOrderDetails, getSelectedCompany, isCurrentInterfaceConfigurationLoading, isFeatureEnabled,
} from '../../../store/selectors';
import { DetailsRow, DetailsPaneCol, VehicleDetailsWrapper } from '../../VehicleDetails/VehicleDetails.styled';
import OrderDetailsPane from './OrderDetailsPane';
import OrderPositionsMap from './OrderPositionsMap';
import {
  deactivateOrder, fetchOrderDetails, fetchOrderPositions, resetEta, setOrderDetails, setTitle,
  fetchPositionsAnalysis, generateMonitoringReport, generateReportValidationError, FeatureToggle, cleanOrderDetails,
  fetchMonitoringOrderShapshot,
} from '../../../store/actions';
import OrderDetailsExtrasBar from './OrderDetailsExtrasBar';
import OrdersTableHeader from '../Components/OrdersTableHeader';
import { isSuccess } from '../../../utils/responseUtils';
import { OrderContext } from '../../../types';
import NOP from '../../../utils/NOP';
import { geofencing } from '../../../store/actions/orders/geofencing.actions';
import { DocumentType } from '../../../types/enums/documentType.enum';
import openShareComponent from '../Components/ShareComponent';
import { OrderStatus as OrderStatusEnum, isExpired } from '../../../types/enums/orderType.enum';

import { ERROR_CODE } from '../../../utils/apiError';

const OrderDetailsComponent = ({ match: { params: { side: context, shipperId, orderId } } }) => {
  const { t } = useTranslation();
  const [selectedVehicle, setSelectedVehicle] = useState(null);
  const [renderBreaks, setRenderBreaks] = useState(true);
  const {
    order, positions, eta, details, analysis,
  } = useSelector(getOrderDetails);
  const history = useHistory();
  const geofencingWithEtaEnabled = useSelector(isFeatureEnabled(FeatureToggle.SHOW_GEOFENCING_WITH_ETA));
  const geofencingData = useSelector(getGeofencing);
  const interfaceLoading = useSelector(isCurrentInterfaceConfigurationLoading);
  const contextCompanyId = useSelector(getSelectedCompany);
  const dispatch = useDispatch();
  const contextPathFragment = context === OrderContext.IN ? 'incoming' : 'outgoing';
  const [showProbalyRoute, setShowProbablyRoute] = useState(true);

  useEffect(() => () => {
    dispatch(cleanOrderDetails());
  }, [dispatch]);

  const fetchAllData = useCallback(() => {
    Promise.all([
      dispatch(fetchOrderDetails(shipperId, orderId)),
      dispatch(fetchOrderPositions(shipperId, orderId)),
    ]).then(([detailsAction, positionsAction]) => {
      dispatch(setOrderDetails({ detailsAction, positionsAction }));
      const orderDetailsData = detailsAction.payload.data;
      if (orderDetailsData.type === 'TOUR' && geofencingWithEtaEnabled) {
        if (!isExpired(orderDetailsData.status)) {
          dispatch(geofencing(orderDetailsData.id, contextCompanyId));
        } else {
          dispatch(fetchMonitoringOrderShapshot(orderDetailsData.id, contextCompanyId))
            .then((snapshotAction) => {
              if (snapshotAction.error
                && snapshotAction.error.response.data.errorCode === ERROR_CODE.MONITORING_ORDER_SNAPSHOT_NOT_FOUND) {
                dispatch(geofencing(orderDetailsData.id, contextCompanyId));
              }
            });
        }
      }
    });
  }, [dispatch, orderId, shipperId, geofencingWithEtaEnabled, contextCompanyId]);

  useEffect(() => {
    fetchAllData();
    return () => dispatch(resetEta());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchAllData]);

  useEffect(() => {
    function setTabTitle() {
      const title = order.data.internalId;
      if (title) {
        dispatch(setTitle({ title }));
      }
    }

    if (!order.data) {
      return;
    }
    setTabTitle();
  }, [dispatch, order.data]);

  useEffect(() => {
    setSelectedVehicle(positions.data && Object.keys(positions.data) && Object.keys(positions.data)[0]);
  }, [positions]);

  useEffect(() => {
    const fetchStatisticsForVehicle = (p) => {
      dispatch(fetchPositionsAnalysis({
        positions: p,
      }));
    };

    const vehiclePositions = details.positions[selectedVehicle];
    if (vehiclePositions && vehiclePositions.positions.length) {
      return fetchStatisticsForVehicle(vehiclePositions.positions);
    }

    return NOP;
  }, [dispatch, selectedVehicle, details.positions]);

  const onEdit = () => {
    history.push({ pathname: `/orders/edit/${order.data.shipperCompany.id}/${order.data.internalId}` });
  };

  const onDeactivate = () => {
    dispatch(deactivateOrder(order.data.internalId)).then((response) => {
      if (isSuccess(response)) {
        message.success(t('ORDER.DEACTIVATION_SUCCESSFUL', { internalId: order.data.internalId }));
        fetchAllData();
      }
    });
  };

  const [form] = Form.useForm();

  const openShareModal = () => {
    openShareComponent(order.data, contextCompanyId, t, form, dispatch);
  };

  const [formReport] = Form.useForm();
  formReport.setFields([
    { name: 'documentType', value: DocumentType.PDF },
  ]);

  const popupReportForm = (
    <Form
      form={formReport}
      style={{ paddingTop: '24px' }}
    >
      <Form.Item
        name="documentType"
        label={t('GENERATE_REPORT.DOCUMENT_TYPE')}
        rules={[
          {
            required: true,
            message: t('GENERATE_REPORT.PLEASE_SELECT_DOCUMENT_TYPE'),
          },
        ]}
      >
        <Radio.Group>
          {Object.entries(DocumentType).map(([key, val]) => (
            <Radio
              value={val}
              key={val}
            >
              {t(`DICTIONARY.DOCUMENT_TYPE.${key}`)}
            </Radio>
          ))}
        </Radio.Group>
      </Form.Item>
    </Form>
  );

  const onGenerateReport = () => {
    formReport.validateFields()
      .then(() => {
        const documentType = formReport.getFieldValue('documentType');
        dispatch(generateMonitoringReport(order.data.internalId, shipperId, contextCompanyId, documentType));
      })
      .catch((violation) => {
        dispatch(generateReportValidationError(violation));
      });
  };

  const openGenerateReportModal = () => {
    Modal.confirm({
      closable: true,
      icon: null,
      title: t('ORDER.GENERATE_REPORT'),
      content: popupReportForm,
      width: 386,
      onOk: onGenerateReport,
      okText: t('COMMON.DOWNLOAD'),
      cancelText: t('COMMON.CANCEL'),
    });
  };

  const openConfirmDeactivationModal = () => {
    Modal.confirm({
      title: t('COMMON.CONFIRM'),
      content: t('ORDER.DEACTIVATION_CONFIRM'),
      onOk: onDeactivate,
      okText: t('COMMON.OK'),
      cancelText: t('COMMON.CANCEL'),
    });
  };

  const changeShowProbablyRoute = () => {
    setShowProbablyRoute(!showProbalyRoute);
  };

  return (
    <>
      {order.data && (
        <>
          <BreadcrumbsItem to={`/orders/${contextPathFragment}/${order.data.type?.toLowerCase() || 'time'}`}>
            {t(`DICTIONARY.ORDER_TYPE.${context.toUpperCase()}`)}
          </BreadcrumbsItem>
          <BreadcrumbsItem to={`/orders/details/${context}/${shipperId}/${orderId}`}>
            {(order.data && order.data.internalId) || t('BREADCRUMB.ORDER_DETAILS')}
          </BreadcrumbsItem>
        </>
      )}
      <VehicleDetailsWrapper>
        <OrdersTableHeader
          propableRouteButtonShow
          refreshIntervalSeconds={0}
          disableRefreshSelection
          ordersLoading={positions.loading || order.loading}
          onRefresh={fetchAllData}
          lastUpdated={order.lastUpdated}
          interfaceLoading={interfaceLoading}
          onShare={openShareModal}
          onGenerateReport={openGenerateReportModal}
          onDeactivate={context === OrderContext.OUT ? openConfirmDeactivationModal : null}
          onEdit={onEdit}
          editShown={order.data.status === OrderStatusEnum.ACTIVE || order.data.status === OrderStatusEnum.FUTURE}
          showProbalyRoute={changeShowProbablyRoute}
          renderInfo={() => (
            <OrderDetailsExtrasBar
              positions={positions.data}
              loading={positions.loading}
              selectedVehicle={selectedVehicle}
              onVehicleSelected={setSelectedVehicle}
              size="small"
            />
          )}
        />
        <DetailsRow gutter={[10, 10]}>
          <Col xs={16}>
            <OrderPositionsMap
              eta={eta.data[eta.data.latest] && eta.data[eta.data.latest].estimated[selectedVehicle]}
              renderBreaks={renderBreaks}
              analysis={analysis.data}
              vehicle={((order.data && order.data.vehicles) || []).find(({ id }) => id === selectedVehicle)}
              orderDetails={details}
              geofencingWithEtaEnabled={geofencingWithEtaEnabled}
              geofencingData={geofencingData}
              loading={positions.loading}
              showOrHideProbablyRoute={showProbalyRoute}
            />
          </Col>
          <DetailsPaneCol xs={8}>
            <OrderDetailsPane
              geofencingWithEtaEnabled={geofencingWithEtaEnabled}
              eta={eta}
              analysis={analysis}
              renderBreaks={renderBreaks}
              onRenderBreaksChange={setRenderBreaks}
              geofencingData={geofencingData}
              vehiclePositions={positions.data}
              selectedVehicle={selectedVehicle}
              order={order.data}
              side={context}
              loading={order.loading}
            />
          </DetailsPaneCol>
        </DetailsRow>
      </VehicleDetailsWrapper>
    </>
  );
};

OrderDetailsComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      orderId: PropTypes.string.isRequired,
      side: PropTypes.string.isRequired,
      shipperId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default OrderDetailsComponent;
