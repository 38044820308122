import PropTypes from 'prop-types';
import React from 'react';
import StopEta from './StopEta';
import { geofencingDataPropTypes, monitoringOrderSnapshotPropTypes } from '../../../types';
import ArrivingTime from './ArrivingTime';
import MonitoringOrderStopSnapshot from './MonitoringOrderStopSnapshot';
import { stopPropTypes } from '../../../types/stop.type';

const TripStopDetails = ({
  stop, geofencingWithEtaEnabled, eta, hasGeofencingData, geofencingData, monitoringOrderSnapshot,
}) => {
  const handleCalculateButtonClick = () => eta.onCalculate(stop);
  function getStopEta() {
    return geofencingWithEtaEnabled ? (
      <StopEta
        eta={eta.data}
        calculateEta={handleCalculateButtonClick}
        loading={eta.loading}
        disabled={eta.disabled}
        scheduledArrival={stop.timeSlot.end}
        tooltipKey={eta.tooltipKey}
        geofencingData={geofencingData}
      />
    ) : null;
  }

  const stopInfo = () => {
    if (monitoringOrderSnapshot.hasData) {
      return <MonitoringOrderStopSnapshot monitoringOrderSnapshotData={monitoringOrderSnapshot.data} />;
    }
    return hasGeofencingData
      ? (<ArrivingTime geofencingData={geofencingData} />)
      : getStopEta();
  };

  return (
    <>
      {stopInfo()}
    </>
  );
};

TripStopDetails.propTypes = {
  stop: stopPropTypes.isRequired,
  geofencingWithEtaEnabled: PropTypes.bool.isRequired,
  eta: PropTypes.shape({
    loading: PropTypes.bool,
    data: PropTypes.shape({}),
    onCalculate: PropTypes.func,
    tooltipKey: PropTypes.string,
    disabled: PropTypes.bool,
  }).isRequired,
  geofencingData: geofencingDataPropTypes,
  monitoringOrderSnapshot: PropTypes.shape({
    hasData: PropTypes.bool,
    data: monitoringOrderSnapshotPropTypes,
  }).isRequired,
  hasGeofencingData: PropTypes.bool.isRequired,
};

TripStopDetails.defaultProps = {
  geofencingData: {},
};

export default TripStopDetails;
