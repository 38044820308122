import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import moment from 'moment';
import { getCleanMoment } from '../../../utils/dateTimeUtils';
import { ElapsedSpan } from './DateTime.styled';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, MomentPropTypes.momentObj]),
  noBrackets: PropTypes.bool,
  prefix: PropTypes.string,
};

const defaultProps = {
  value: null,
  noBrackets: false,
  prefix: '',
};

const Elapsed = ({
  value, noBrackets, prefix,
}) => {
  const valueAsTimestamp = value && getCleanMoment(value);
  if (!valueAsTimestamp) {
    return <></>;
  }

  if (noBrackets) {
    return (
      <ElapsedSpan layout="vertical">
        {`${prefix}${moment.duration(valueAsTimestamp.diff(moment())).humanize(true)}`}
      </ElapsedSpan>
    );
  }

  return (
    <ElapsedSpan layout="vertical">
      {`(${prefix}${moment.duration(valueAsTimestamp.diff(moment())).humanize(true)})`}
    </ElapsedSpan>
  );
};

Elapsed.propTypes = propTypes;
Elapsed.defaultProps = defaultProps;

export default Elapsed;
