/* eslint-disable no-case-declarations */
import { GEOFENCING_REQUESTED, GET_GEOFENCING_INFO } from '../../actions/orders/geofencing.actions';

export default function geofencingReducer(state = null, { type, payload }) {
  switch (type) {
    case GEOFENCING_REQUESTED:
      const { selectedCrowns } = state;
      selectedCrowns.push(payload.stopId);

      return {
        ...state,
        selectedCrowns,
      };
    case GET_GEOFENCING_INFO.SUCCESS:
      return {
        selectedCrowns: [],
        polygons: payload.data.polygons,
        vehicles: payload.data.vehicles,
      };
    case GET_GEOFENCING_INFO.FAIL:
      return {
        selectedCrowns: [],
        vehicles: [],
        polygons: [],
      };
    default:
      return state;
  }
}
