import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';

export const mappedLastPosition = PropTypes.shape({
  ignitionState: PropTypes.bool,
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  speed: PropTypes.number,
  serverTimestamp: PropTypes.string,
});

export const lastPosition = PropTypes.shape({
  id: PropTypes.string,
  providerId: PropTypes.string,
  providerAccountId: PropTypes.string,
  deviceId: PropTypes.string,
  coordinateLatitude: PropTypes.number,
  coordinateLongitude: PropTypes.number,
  heading: PropTypes.string,
  speed: PropTypes.number,
  ignitionState: PropTypes.bool,
  serverTimestamp: PropTypes.number,
  positionTimestamp: PropTypes.number,
});

export const historicalPosition = PropTypes.shape({
  id: PropTypes.string,
  providerId: PropTypes.string,
  providerAccountId: PropTypes.string,
  deviceId: PropTypes.string,
  coordinateLatitude: PropTypes.number,
  coordinateLongitude: PropTypes.number,
  heading: PropTypes.string,
  speed: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ignitionState: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  serverTimestamp: PropTypes.number,
  positionTimestamp: PropTypes.number,
  providerDeviceId: PropTypes.string,
  dateTimeYear: PropTypes.string,
  dateTimeMonth: PropTypes.string,
  dateTimeDay: PropTypes.string,
  dateTimeHour: PropTypes.string,
  dateTimeMinute: PropTypes.string,
  dateTimeSeconds: PropTypes.string,
  dateTimeTimezone: PropTypes.string,
});

export const orderPositionsPropTypes = PropTypes.shape({
  vehicleId: PropTypes.string.isRequired,
  licencePlateNumber: PropTypes.string.isRequired,
  positions: PropTypes.arrayOf(lastPosition),
});

export const orderPositionsPerVehiclePropTypes = PropTypes.objectOf(orderPositionsPropTypes);

export const orderPositionsResponsePropTypes = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  data: orderPositionsPerVehiclePropTypes,
  lastUpdated: MomentPropTypes.momentObj,
});
