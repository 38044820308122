import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Button, Col, Row,
} from 'antd';
import PropTypes from 'prop-types';
import { ArrowRightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router';
import {
  DateTime, DetailsDescriptionsCard, IgnitionState, LatLng, Speed, VehicleOwnership,
} from '../../../components';
import VehicleNote from '../../../components/Vehicle/Data/VehicleNote';
import { sharedVehicle } from '../../../types';
import GetLocation from '../../../components/Vehicle/Data/GetLocation';
import Elapsed from '../../../components/Vehicle/Data/Elapsed';

const VehicleDetailsPane = ({
  vehicle, cardSize, detailsSize,
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const [position, setPosition] = useState({});
  const [vehicleDetails, setVehicleDetails] = useState({});
  const [sharing, setSharing] = useState({});

  useEffect(() => {
    if (!vehicle.data) {
      setPosition({});
      setVehicleDetails({});
      setSharing({});
      return;
    }

    const {
      useDateFrom, useDateTo, ownershipType, createdDate,
    } = vehicle.data;
    const { lastPosition } = vehicle.data.vehicle;

    if (lastPosition && lastPosition.length) {
      setPosition(lastPosition[0]);
    }
    setVehicleDetails(vehicle.data.vehicle);
    setSharing({
      useDateFrom, useDateTo, ownershipType, createdDate,
    });
  }, [vehicle]);

  const goToSharing = () => {
    history.push({ pathname: '/sharings' });
  };

  const details = {
    vehicle: [{
      title: 'PROPERTIES.VEHICLE_PLATE_NO',
      renderDetail: () => vehicleDetails.licencePlateNumber || t('COMMON.MISSING'),
      loading: () => vehicle.loading,
    }, {
      title: 'PROPERTIES.NOTES',
      renderDetail: () => (<VehicleNote vehicleId={vehicleDetails.id} />),
      loading: () => vehicle.loading,
    }, {
      title: 'PROPERTIES.COORDINATES',
      renderDetail: () => (
        <LatLng
          latitude={position.coordinateLatitude}
          longitude={position.coordinateLongitude}
          copy
        />
      ),
      loading: () => vehicle.loading,
    }, {
      title: 'PROPERTIES.LAST_LOCATION',
      renderDetail: () => (
        vehicleDetails.id && (
        <GetLocation
          hideRefresh
          fetchOnStart
          vehicleId={vehicleDetails.id}
          coordinates={{ lng: position.coordinateLongitude, lat: position.coordinateLatitude }}
        />
        )
      ),
      loading: () => vehicle.loading,
    }, {
      title: 'PROPERTIES.LAST_UPDATE',
      renderDetail: () => (
        <>
          <DateTime
            value={position.positionTimestamp}
            elapsed
            noBrackets
          />
          {position.serverTimestamp && (
            <span style={{ fontSize: '10px', color: 'gray' }}>
              <Elapsed value={position.serverTimestamp} prefix={`${t('PROPERTIES.LAST_CHECK')} `} />
            </span>
          )}
        </>
      ),
      loading: () => vehicle.loading,
    }, {
      title: 'PROPERTIES.SPEED',
      renderDetail: () => <Speed value={position.speed} />,
      loading: () => vehicle.loading,
    }, {
      title: 'PROPERTIES.IGNITION',
      renderDetail: () => (
        <IgnitionState
          state={position.ignitionState}
          icon
          label
        />
      ),
      loading: () => vehicle.loading,
    }],
    company: [{
      title: 'PROPERTIES.NAME',
      loading: () => vehicle.loading,
      renderDetail: () => (vehicleDetails.vehicleCompany ? vehicleDetails.vehicleCompany.name : t('COMMON.MISSING')),
    }],
    sharing: [{
      title: 'PROPERTIES.STATUS',
      renderDetail: () => (
        <VehicleOwnership
          type={sharing.ownershipType}
          showLabel
          size="s"
          justify="start"
        />
      ),
      loading: () => vehicle.loading,
    }, {
      title: 'PROPERTIES.START_DATE',
      renderDetail: () => (<DateTime value={sharing.useDateFrom} />),
      loading: () => vehicle.loading,
    }, {
      title: 'PROPERTIES.END_DATE',
      renderDetail: () => (<DateTime value={sharing.useDateTo} />),
      loading: () => vehicle.loading,
    }],
  };

  return (
    <Row gutter={[10, 10]}>
      <Col xs={24}>
        <DetailsDescriptionsCard
          cardSize={cardSize}
          title={t('PROPERTIES.COMPANY')}
          detailsSize={detailsSize}
          detailsConfig={details.company}
        />
      </Col>
      <Col xs={24}>
        <DetailsDescriptionsCard
          cardSize={cardSize}
          title={t('VEHICLE.VEHICLE_DETAILS')}
          detailsSize={detailsSize}
          detailsConfig={details.vehicle}
        />
      </Col>
      <Col xs={24}>
        <DetailsDescriptionsCard
          cardSize={cardSize}
          title={t('PROPERTIES.SHARING')}
          detailsSize={detailsSize}
          detailsConfig={details.sharing}
          actions={[
            <Button
              type="link"
              key="go-to-sharing"
              disabled={vehicle.loading}
              icon={<ArrowRightOutlined />}
              onClick={goToSharing}
            >
              {' '}
              {t('COMMON.VIEW_SHARING')}
            </Button>,
          ]}
        />
      </Col>
    </Row>
  );
};

VehicleDetailsPane.propTypes = {
  vehicle: PropTypes.shape({
    data: sharedVehicle,
    loading: PropTypes.bool,
  }).isRequired,
  cardSize: PropTypes.oneOf(['small', 'medium', 'large']),
  detailsSize: PropTypes.oneOf(['small', 'medium', 'large']),
};

VehicleDetailsPane.defaultProps = {
  cardSize: 'medium',
  detailsSize: 'small',
};

export default VehicleDetailsPane;
