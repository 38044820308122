import moment from 'moment/moment';

export const isNameMatchingFilter = (filter, { name }) => !filter || (
  name && name.toLowerCase().includes(filter.toLowerCase())
);

export const shouldShowTimeframes = (startDate, endDate, periods) => {
  if (!startDate || !endDate || !periods) {
    return false;
  }
  return periods
    .filter((period) => moment.utc(period.start).unix() !== startDate || moment.utc(period.end).unix() !== endDate)
    .length > 0;
};
