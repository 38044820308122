import styled from 'styled-components';
import { Col, Radio, Row } from 'antd';
import { FlexColumn } from '../../components';

export const VehicleDetailsWrapper = styled(FlexColumn)`
  padding-bottom: 20px;
  padding-top: 7px;
`;

export const DetailsPaneCol = styled(Col)`
  overflow-y: auto;
`;

export const DetailsTopRow = styled(Row)`
  height: 25px;
`;

export const DetailsRow = styled(Row)`
  margin-top: 7px;
  flex-flow: nowrap;
  height: calc(100% - 25px);
  flex: 0 0 auto;
`;

export const MapModeSwitch = styled(Radio.Group)`
  margin-right: 30px;
`;
