import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import DetailsDescriptions from '../../../../components/Styled/Custom/DetailsDescriptions';
import { DateTime, FullWidthStyledCard } from '../../../../components';
import { CardHandler } from '../../compontents/RequestComponent';

const TimeOrderDetailsDescription = ({ requestDetails }) => {
  const { t } = useTranslation();

  const timeMonitoringDetails = [
    {
      title: 'REQUEST.START_DATE',
      renderDetail: () => (
        <DateTime
          value={requestDetails.timeMonitoringStartDate}
          elapsed
        />
      ),
      loading: () => requestDetails.loading,
    },
    {
      title: 'REQUEST.END_DATE',
      renderDetail: () => (
        <DateTime
          value={requestDetails.timeMonitoringEndDate}
          elapsed
        />
      ),
      loading: () => requestDetails.loading,
    },
  ];

  return (
    <CardHandler>
      <FullWidthStyledCard title={t('REQUEST.MONITORING_DETAILS')}>
        <DetailsDescriptions
          detailsSize="small"
          detailsConfig={timeMonitoringDetails}
        />
      </FullWidthStyledCard>
    </CardHandler>
  );
};

TimeOrderDetailsDescription.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  requestDetails: PropTypes.object.isRequired,
};

export default TimeOrderDetailsDescription;
