import React from 'react';
import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { useTranslation } from 'react-i18next';
import { getCleanMoment } from '../../../utils/dateTimeUtils';
import { DEFAULT_DATE_TIME } from '../../../utils/constants/timeFormats';
import Elapsed from './Elapsed';

const propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, MomentPropTypes.momentObj]),
  elapsed: PropTypes.bool,
  noBrackets: PropTypes.bool,
};

const defaultProps = {
  value: null,
  elapsed: false,
  dateTimeFormat: DEFAULT_DATE_TIME,
  noBrackets: false,
};

const DateTime = ({
  value, elapsed, dateTimeFormat, noBrackets,
}) => {
  const valueAsTimestamp = value && getCleanMoment(value);
  const { t } = useTranslation();

  const formattedOrEmpty = (timestamp) => (timestamp ? timestamp.format(dateTimeFormat) : t('COMMON.MISSING'));

  const renderComponent = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'baseline',
        flexDirection: 'column',
      }}
    >
      <span>{formattedOrEmpty(valueAsTimestamp)}</span>
      {elapsed && (
        <Elapsed noBrackets={noBrackets} value={value} />
      )}
    </div>
  );

  const renderMissing = () => (<span>{t('COMMON.MISSING')}</span>);

  return (valueAsTimestamp ? renderComponent() : renderMissing());
};

DateTime.propTypes = propTypes;
DateTime.defaultProps = defaultProps;

export default DateTime;
