import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'antd';
import { loadRequestDetails } from '../../../store/actions/requests/request-details.actions';
import { getMeId, getRequestDetails } from '../../../store/selectors';
import { StyledCard } from '../../../components';
import { DetailsPaneCol } from '../../VehicleDetails/VehicleDetails.styled';
import RequestStatusTimeline from './Components/RequestStatusTimeline';
import RequestDetailsPane from './Components/RequestDetailsPane';
import RequestDetailsActionComponent from './Components/RequestDetailsActionComponent';
import { isIncoming, isPending } from '../../../utils/monitoringRequests';
import { deactivateMonitoringRequestCommand } from '../../../store/actions';

const RequestDetailsComponent = ({ match: { params: { requestId } } }) => {
  const { t } = useTranslation();
  const { requestDetails } = useSelector(getRequestDetails);
  const dispatch = useDispatch();
  const userId = useSelector(getMeId);

  useEffect(() => {
    if (requestId) {
      dispatch(loadRequestDetails(requestId));
    }
  }, [requestId, dispatch]);

  const onDeactivate = () => {
    new Promise((resolve) => {
      dispatch(deactivateMonitoringRequestCommand({
        monitoringRequestId: requestDetails.data.id,
        shipperCompanyId: requestDetails.data.shipperInfo.companyId,
        deactivateData: {
          deactivatedBy: userId,
        },
      }));
      resolve();
    })
      .then(() => new Promise((resolve) => setTimeout(resolve, 1000)))
      .then(() => dispatch(loadRequestDetails(requestId)));
  };

  const onActionClick = () => {
    dispatch(loadRequestDetails(requestId));
  };

  return (
    <>
      {!requestDetails.loading && !requestDetails.error && requestDetails.data
        ? (
          <>
            <BreadcrumbsItem to="/requests">{t('BREADCRUMB.REQUESTS')}</BreadcrumbsItem>
            <BreadcrumbsItem to={`/requests/${requestDetails.data?.requestType?.toLowerCase()}`}>
              {t(`BREADCRUMB.REQUEST_TYPE.${requestDetails.data?.requestType}`)}
            </BreadcrumbsItem>
            <BreadcrumbsItem to={`/requests/${requestDetails.data?.id}/details`}>
              {(requestDetails.data.orderNumber) || t('BREADCRUMB.REQUEST_DETAILS')}
            </BreadcrumbsItem>
            <Row gutter={[10, 10]} style={{ overflowY: 'auto', paddingTop: '7px', marginBottom: '20px' }}>
              <Col xs={10}>
                {isIncoming(requestDetails.data) && isPending(requestDetails.data)
                  ? <RequestDetailsActionComponent requestDetails={requestDetails.data} onActionClick={onActionClick} />
                  : null}
                <RequestDetailsPane onDeactivate={onDeactivate} requestDetails={requestDetails.data} />
              </Col>
              <DetailsPaneCol xs={10}>
                <StyledCard title={t('REQUEST.TIMELINE')}>
                  <RequestStatusTimeline requestDetails={requestDetails.data} />
                </StyledCard>
              </DetailsPaneCol>
            </Row>
          </>
        )
        : null}
    </>
  );
};

RequestDetailsComponent.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      requestId: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
};

export default RequestDetailsComponent;
