import React, { useEffect, useState } from 'react';
import {
  Form, Modal, Radio, Select,
} from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { closeGenerateReport, generateReportValidationError, submitGenerateReport } from '../../../store/actions';
import { DocumentType } from '../../../types/enums/documentType.enum';
import { DEFAULT_DATE_TIME, DEFAULT_TIME } from '../../../utils/constants/timeFormats';
import { FormItem } from './GenerateReportModal.styled';
import { resetFleetSelectionMode } from '../../../store/actions/fleet';
import { getGenerateReportModalData } from '../../../store/selectors';
import { StyledRangePicker } from '../../Styled/Custom';

const GenerateReportModal = () => {
  const {
    loading, vehicles, selectedVehicle, visible, fixed,
  } = useSelector(getGenerateReportModalData);
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [dateRange, setDateRange] = useState([]);
  const [selectVehicleOptions, setSelectVehiclesOptions] = useState([]);

  useEffect(() => {
    form.setFields([
      { name: 'selectedVehicle', value: selectedVehicle && selectedVehicle.vehicleId },
      { name: 'type', value: DocumentType.PDF },
    ]);
  }, [selectedVehicle, form]);

  useEffect(() => {
    const vehiclesById = { };
    if (selectedVehicle) {
      vehiclesById[selectedVehicle.vehicleId] = selectedVehicle;
    }
    vehicles.forEach((v) => {
      vehiclesById[v.vehicleId] = v;
    });
    setSelectVehiclesOptions(Object.values(vehiclesById));
  }, [vehicles, selectedVehicle]);

  const handleOk = () => {
    form.validateFields()
      .then((values) => {
        dispatch(submitGenerateReport(values));
        dispatch(resetFleetSelectionMode());
      })
      .catch((violation) => {
        dispatch(generateReportValidationError(violation));
      });
  };

  const handleCancel = () => {
    form.resetFields();
    dispatch(closeGenerateReport());
    dispatch(resetFleetSelectionMode());
  };

  const disabledDate = (current) => current && dateRange && !(dateRange[0] && dateRange[1]) && (
    (dateRange[0] && dateRange[0].clone().add(14, 'd') <= current)
    || (dateRange[1] && dateRange[1].clone().subtract(14, 'd') >= current));

  const timeRangeValidator = () => {
    const startDate = form.getFieldValue('dateRange')[0];
    const endDate = form.getFieldValue('dateRange')[1];
    if (startDate && endDate) {
      const dateRangeInMinutes = endDate.diff(startDate, 'minutes');
      if (dateRangeInMinutes > 7 * 24 * 60) {
        return Promise.reject();
      }
    }
    return Promise.resolve();
  };

  return (
    <Modal
      destroyOnClose
      open={visible}
      title={t('VEHICLE.GENERATE_REPORT')}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={loading}
      width={386}
      okText={t('COMMON.DOWNLOAD')}
    >
      <Form
        form={form}
        layout="vertical"
        name="generateReport"
      >
        <FormItem
          name="selectedVehicle"
          label={t('GENERATE_REPORT.VEHICLE')}
          rules={[
            {
              required: true,
              message: t('GENERATE_REPORT.PLEASE_SELECT_VEHICLE'),
            },
          ]}
        >
          <Select
            showSearch
            disabled={fixed}
            optionFilterProp="children"
            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
          >
            {selectVehicleOptions.map(({ vehicleId, licencePlateNumber }) => (
              <Select.Option
                value={vehicleId}
                key={vehicleId}
              >
                {licencePlateNumber}
              </Select.Option>
            ))}
          </Select>
        </FormItem>
        <FormItem
          name="dateRange"
          label={t('GENERATE_REPORT.TIME_RANGE')}
          rules={[
            {
              required: true,
              message: t('GENERATE_REPORT.PLEASE_PROVIDE_TIME_RANGE'),
            }, {
              validator: timeRangeValidator,
              message: t('GENERATE_REPORT.TIME_RANGE_CAN_BE_MAX_7_DAYS'),
            },
          ]}
        >
          <StyledRangePicker
            showTime={{ format: DEFAULT_TIME }}
            format={DEFAULT_DATE_TIME}
            onChange={setDateRange}
            onOk={setDateRange}
            disabledDate={disabledDate}
            dropdownClassName="ant-picker-shadow-2"
          />
        </FormItem>
        <FormItem
          name="type"
          label={t('GENERATE_REPORT.DOCUMENT_TYPE')}
          rules={[
            {
              required: true,
              message: t('GENERATE_REPORT.PLEASE_SELECT_DOCUMENT_TYPE'),
            },
          ]}
        >
          <Radio.Group>
            {Object.entries(DocumentType).map(([key, val]) => (
              <Radio
                value={val}
                key={val}
              >
                {t(`DICTIONARY.DOCUMENT_TYPE.${key}`)}
              </Radio>
            ))}
          </Radio.Group>
        </FormItem>
      </Form>
    </Modal>
  );
};

export default GenerateReportModal;
