import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Select } from 'antd';
import { useTranslation } from 'react-i18next';
import { orderPositionsPerVehiclePropTypes } from '../../../types';
import { HeaderButtonsDivider } from '../../../components';

function getVehiclesOptions(positions) {
  return positions && Object.values(positions)
    .filter((p) => !!p)
    .map(({ licencePlateNumber, vehicleId }) => ({
      label: licencePlateNumber,
      value: vehicleId,
    }));
}

const OrderDetailsExtrasBar = ({
  positions, loading, selectedVehicle, onVehicleSelected, size,
}) => {
  const { t } = useTranslation();
  const [vehicleOptions, setVehicleOptions] = useState([]);

  useEffect(() => setVehicleOptions(getVehiclesOptions(positions)), [setVehicleOptions, positions]);

  if (vehicleOptions.length <= 1) return (<></>);

  return (
    <>
      <Select
        style={{ minWidth: '120px' }}
        size={size}
        disabled={loading || !vehicleOptions.length}
        value={selectedVehicle || t('COMMON.NO_VEHICLE_SELECTED')}
        onChange={onVehicleSelected}
        options={vehicleOptions}
      />
      <HeaderButtonsDivider />
    </>
  );
};

OrderDetailsExtrasBar.propTypes = {
  selectedVehicle: PropTypes.string,
  positions: orderPositionsPerVehiclePropTypes,
  loading: PropTypes.bool.isRequired,
  onVehicleSelected: PropTypes.func.isRequired,
  size: PropTypes.oneOf(['small', 'medium', 'large']),
};

OrderDetailsExtrasBar.defaultProps = {
  positions: {},
  selectedVehicle: null,
  size: 'small',
};

export default OrderDetailsExtrasBar;
