import { Tooltip, Typography } from 'antd';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Link, useRouteMatch } from 'react-router-dom';
import { SearchOutlined, CloseCircleTwoTone } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { OwnershipType } from '../../../../types';
import {
  CompanyInfo, DateTime, GetLocation, Highlighteable, IgnitionState, InputFilterDropdown, LatLng, Speed,
  VehicleOwnership, VehicleType, MapIgnitionState,
} from '../../../../components';
import Actions from './ActionsComponent';
import {
  closeLastTraceMode, mergeUpdateInterfaceFilters, updateInterfaceColumns, setVehiclesSorting,
} from '../../../../store/actions';
import {
  ALL_COLS, COL, COL_WIDTHS, createColToVisibilityMap, FILTERS,
} from './VehiclesTable.constants';
import { ShowOnMapButton } from './ActionsComponent.styled';
import {
  getVehicles, getVehiclesColumns, TableName, getLastTraceMode, containsAnyNotes,
} from '../../../../store/selectors';
import VehiclesTableComponent from './VehiclesTableComponent';
import VehicleService from '../../../../services/VehicleService';
import VehiclesTableHeader from './VehiclesTableHeader';
import ignitionStateToColor from '../../../../utils/constants/ignitionStateToColor';
import theme from '../../../../theme';
import NOP from '../../../../utils/NOP';
import Elapsed from '../../../../components/Vehicle/Data/Elapsed';

function getShowOnMapTooltip(row) {
  if (!row.lastPosition.latitude || !row.lastPosition.longitude) {
    return 'COMMON.NO_LOCATION';
  }

  return 'COMMON.SHOW_ON_MAP';
}

function isShowOnMapDisabled(row) {
  return !row.lastPosition.latitude || !row.lastPosition.longitude;
}

const Text = styled(Typography.Paragraph)`
  margin-bottom: 0 !important;
`;

const VehiclesTable = ({
  size, viewportHeight, onVehicleSelected, onRefresh, filters, updateFilters, clearFilters,
}) => {
  const columns = useSelector(getVehiclesColumns);
  const { selectedVehicle } = useSelector(getVehicles);
  const lastTraceVehicleId = useSelector(getLastTraceMode);
  const visibleNotes = useSelector(containsAnyNotes);
  const [visibleColumns, setVisibleColumns] = useState(createColToVisibilityMap(ALL_COLS));

  const { t } = useTranslation();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();

  const showOnMap = (record) => {
    if (selectedVehicle && (record.vehicleId === selectedVehicle.vehicleId)) {
      onVehicleSelected(null);
    } else {
      onVehicleSelected(record);
    }
  };

  const setFilters = (filter) => {
    dispatch(mergeUpdateInterfaceFilters(TableName.Vehicles, filter));
  };

  const dispatchCloseLastTrace = () => dispatch(closeLastTraceMode());

  const handleShowOnMap = (row) => () => showOnMap(row);

  const renderShowOnMap = (_, row) => (lastTraceVehicleId === row.vehicleId ? (
    <Tooltip
      title={t('VEHICLE.CLOSE_LAST_TRACE')}
      placement="top"
    >
      <ShowOnMapButton
        type="link"
        size="small"
        icon={(
          <CloseCircleTwoTone twoToneColor={theme.color.routeStroke} />
        )}
        onClick={dispatchCloseLastTrace}
      />
    </Tooltip>
  ) : (
    <Tooltip
      title={t(getShowOnMapTooltip(row))}
      placement="top"
    >
      <ShowOnMapButton
        type="link"
        size="small"
        disabled={isShowOnMapDisabled(row)}
        icon={(
          <MapIgnitionState
            color={isShowOnMapDisabled(row)
              ? theme.color.disabled
              : ignitionStateToColor(row.lastPosition.ignitionState)}
          />
        )}
        onClick={handleShowOnMap(row)}
      />
    </Tooltip>
  ));

  const renderLicencePlate = (text, row) => (
    <Link to={`${url}/${row.vehicleId}/${row.vehicleCompany.id}`}>
      <Highlighteable
        search={filters[FILTERS.VEHICLE_ID.name] && filters[FILTERS.VEHICLE_ID.name][0]}
        content={row.licencePlateNumber}
      />
    </Link>
  );

  const renderNotes = (_, { remark }) => (
    <Text>
      {remark || t('COMMON.MISSING')}
    </Text>
  );

  const renderOwnership = (_, { ownershipType }) => <VehicleOwnership type={ownershipType} />;

  const renderCompany = (_, { vehicleCompany }) => (
    <CompanyInfo
      company={vehicleCompany}
      highlight={filters[FILTERS.COMPANY_NAME.name] && filters[FILTERS.COMPANY_NAME.name][0]}
    />
  );

  const companyFilter = (value, vehicle) => VehicleService.isCompanyMatchingFilter(value, vehicle);

  const renderLastLocation = (_, { vehicleId, lastPosition }) => (
    <GetLocation
      vehicleId={vehicleId}
      coordinates={{ lat: lastPosition.latitude, lng: lastPosition.longitude }}
    />
  );

  const renderLastPosition = (_, { lastPosition }) => (
    <LatLng
      copy
      latitude={lastPosition.latitude}
      longitude={lastPosition.longitude}
    />
  );

  const renderLastUpdate = (_, { lastPosition }) => (
    <div>
      <DateTime
        value={lastPosition.positionTimestamp}
        elapsed
        noBrackets
      />
      {lastPosition.serverTimestamp && (
        <span style={{ fontSize: '10px', color: 'gray' }}>
          <Elapsed value={lastPosition.serverTimestamp} prefix={`${t('PROPERTIES.LAST_CHECK')} `} />
        </span>
      )}
    </div>
  );

  const renderSpeed = (_, { lastPosition }) => (
    <Speed value={lastPosition.speed} />
  );

  const renderIgnitionState = (_, row) => (
    <IgnitionState
      state={row.lastPosition.ignitionState}
      label
    />
  );

  const renderVehicleType = (_, row) => <VehicleType type={row.vehicleType} />;

  const renderMake = (_, row) => (
    <Highlighteable
      search={filters[FILTERS.MAKE.name] && filters[FILTERS.MAKE.name][0]}
      content={row.make}
    />
  );

  const disabledSharing = (row) => row.ownershipType === OwnershipType.SHARING;

  const renderActions = (_, row) => (<Actions vehicle={row} disabledSharing={disabledSharing(row)} />);

  const renderColumns = [
    {
      visible: true,
      title: t('PROPERTIES.MAP'),
      key: 'showOnMap',
      className: 'ant-table-cell-centered',
      width: COL_WIDTHS[COL.SHOW_ON_MAP],
      render: renderShowOnMap,
    },
    {
      visible: visibleColumns[COL.VEHICLE_ID],
      title: t('PROPERTIES.VEHICLE_ID'),
      dataIndex: 'licencePlateNumber',
      key: 'licencePlateNumber',
      render: renderLicencePlate,
      filteredValue: filters.VEHICLE_ID,
      filterDropdown: InputFilterDropdown(FILTERS.VEHICLE_ID, setFilters, t),
      onFilter: VehicleService.isLicencePlateMatchingFilter,
      filterIcon: <SearchOutlined />,
      width: COL_WIDTHS[COL.VEHICLE_ID],
    },
    {
      visible: visibleNotes,
      title: t('PROPERTIES.NOTES'),
      dataIndex: 'remark',
      key: 'remark',
      render: renderNotes,
      width: COL_WIDTHS[COL.NOTES],
    },
    {
      visible: visibleColumns[COL.STATUS],
      title: t('PROPERTIES.STATUS'),
      dataIndex: 'ownershipType',
      key: 'ownershipType',
      className: 'ant-table-cell-centered',
      render: renderOwnership,
      width: COL_WIDTHS[COL.STATUS],
    },
    {
      visible: visibleColumns[COL.COMPANY_NAME],
      title: t('PROPERTIES.COMPANY_NAME'),
      dataIndex: 'vehicleCompany.name',
      key: 'vehicleCompany.name',
      render: renderCompany,
      sorter: true,
      defaultSortOrder: 'ascend',
      filterDropdown: InputFilterDropdown(FILTERS.COMPANY_NAME, setFilters, t),
      onFilter: companyFilter,
      filterIcon: <SearchOutlined />,
      width: COL_WIDTHS[COL.COMPANY_NAME],
    },
    {
      visible: visibleColumns[COL.LAST_LOCATION],
      title: t('PROPERTIES.LAST_LOCATION'),
      render: renderLastLocation,
      width: COL_WIDTHS[COL.LAST_LOCATION],
    },
    {
      visible: visibleColumns[COL.COORDINATES],
      title: t('PROPERTIES.COORDINATES'),
      render: renderLastPosition,
      width: COL_WIDTHS[COL.COORDINATES],
    },
    {
      visible: visibleColumns[COL.LAST_UPDATE],
      title: t('PROPERTIES.LAST_UPDATE'),
      render: renderLastUpdate,
      width: COL_WIDTHS[COL.LAST_LOCATION],
    },
    {
      visible: visibleColumns[COL.IGNITION],
      title: t('PROPERTIES.IGNITION'),
      dataIndex: 'lastPosition.ignitionState',
      key: 'lastPosition.ignitionState',
      width: COL_WIDTHS[COL.IGNITION],
      render: renderIgnitionState,
    },
    {
      visible: visibleColumns[COL.SPEED],
      title: t('PROPERTIES.SPEED'),
      dataIndex: 'lastPosition.speed',
      key: 'lastPosition.speed',
      render: renderSpeed,
      width: COL_WIDTHS[COL.SPEED],
    },
    {
      visible: visibleColumns[COL.TYPE],
      title: t('PROPERTIES.TYPE'),
      dataIndex: 'vehicleType',
      key: 'vehicleType',
      width: COL_WIDTHS[COL.TYPE],
      render: renderVehicleType,
    },
    {
      visible: visibleColumns[COL.MAKE],
      title: t('PROPERTIES.MAKE'),
      dataIndex: 'make',
      key: 'make',
      filteredValue: filters.MAKE,
      filterDropdown: InputFilterDropdown(FILTERS.MAKE, setFilters, t),
      onFilter: VehicleService.isMakeMatchingFilter,
      filterIcon: <SearchOutlined />,
      render: renderMake,
      width: COL_WIDTHS[COL.MAKE],
    },
    {
      visible: true,
      className: 'ant-table-cell-centered ant-table-cell-actions',
      key: 'actions',
      fixed: 'right',
      width: COL_WIDTHS[COL.ACTIONS],
      render: renderActions,
    },
  ];

  const [tableColumns, setTableColumns] = useState(renderColumns);

  useEffect(() => {
    setVisibleColumns(createColToVisibilityMap(columns));
  }, [columns, lastTraceVehicleId]);

  useEffect(() => {
    setTableColumns(renderColumns.filter(({ visible }) => visible));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visibleColumns, filters, visibleNotes]);

  const saveColumnsConfig = (newColumnsVisibility) => {
    const newColumns = Object.entries(newColumnsVisibility).filter(([, visible]) => visible).map(([key]) => key);
    return dispatch(updateInterfaceColumns(TableName.Vehicles, newColumns));
  };

  const setSortingAndRefresh = (sort) => {
    dispatch(setVehiclesSorting(sort));
    onRefresh();
  };

  const handleTableChange = (p, f, sorter, { action }) => {
    if (action === 'sort') {
      setSortingAndRefresh(sorter);
    }
  };

  return (
    <>
      <VehiclesTableHeader
        columns={visibleColumns}
        updateColumns={saveColumnsConfig}
        filters={filters}
        updateFilters={updateFilters}
        clearFilters={clearFilters}
        onRefresh={onRefresh}
      />
      <VehiclesTableComponent
        columns={tableColumns}
        viewportHeight={viewportHeight}
        size={size}
        onRefresh={onRefresh}
        onChange={handleTableChange}
      />
    </>
  );
};

VehiclesTable.propTypes = {
  size: PropTypes.oneOf(['small', 'default', 'large']),
  viewportHeight: PropTypes.number.isRequired,
  onVehicleSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  filters: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.any)),
  updateFilters: PropTypes.func,
  clearFilters: PropTypes.func,
};

VehiclesTable.defaultProps = {
  filters: {},
  size: 'small',
  updateFilters: NOP,
  clearFilters: NOP,
};

export default VehiclesTable;
