import moment from 'moment';
import { DEFAULT_DATE_TIME } from './constants/timeFormats';

export const toElapsedFriendlyString = (valueAsDate, t) => {
  if (!valueAsDate) {
    return t('COMMON.MISSING');
  }
  const elapsed = moment.duration(moment().diff(valueAsDate));
  if (elapsed.asMinutes() < 1) {
    return `(${t('COMMON.TIME.JUST_NOW')})`;
  }
  if (elapsed.asMinutes() < 2) {
    return `(${t('COMMON.TIME.A_MINUTE_AGO')})`;
  }
  if (elapsed.asHours() < 1) {
    return `(${Math.floor(elapsed.asMinutes())} ${t('COMMON.TIME.MINUTES_AGO')})`;
  }
  if (elapsed.asHours() < 2) {
    return `(${t('COMMON.TIME.AN_HOUR_AGO')})`;
  }
  if (elapsed.asDays() < 2) {
    return `(${Math.floor(elapsed.asHours())} ${t('COMMON.TIME.HOURS_AGO')})`;
  }

  return `(${Math.floor(elapsed.asDays())} ${t('COMMON.TIME.DAYS_AGO')})`;
};

export const prettyMoment = (timestamp) => {
  if (!timestamp) {
    return null;
  }

  const asMoment = moment.unix(timestamp);
  if (!asMoment || !asMoment.isValid()) {
    return null;
  }

  return asMoment.format(DEFAULT_DATE_TIME);
};

const MIN_MILLIS_TIMESTAMP = 1000000000000;
export const getCleanMoment = (somethingDirty) => {
  if (!somethingDirty) {
    return somethingDirty;
  }

  let epoch = somethingDirty;
  if (typeof somethingDirty === 'string' && moment(somethingDirty).isValid()) {
    epoch = moment.utc(somethingDirty).unix();
  }

  if (epoch > MIN_MILLIS_TIMESTAMP) {
    return moment.unix(Number.parseInt(epoch, 10) / 1000);
  }
  return moment.unix(epoch);
};

export const getCleanDuration = (somethingDirty) => {
  if (!somethingDirty) {
    return somethingDirty;
  }

  if (moment.isDuration(somethingDirty)) {
    return somethingDirty.clone();
  }

  // parse as seconds
  return moment.duration(Number.parseInt(somethingDirty, 10), 's');
};
