import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Typography, Row, Col, Tooltip, Button, Input, Form,
} from 'antd';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { EditOutlined } from '@ant-design/icons';
import { SuccessCheckOutlined, CancelCloseOutlined } from '../..';
import {
  submitAddVehicleRemark, submitEditVehicleRemark, submitRemoveVehicleRemark, openEditVehicleRemark,
  editVehicleRemarkForm, closeEditVehicleRemark,
} from '../../../store/actions/vehicleDetails/vehicleRemark.actions';
import { getVehicleRemark } from '../../../store/selectors';
import { isSuccess } from '../../../utils/responseUtils';

const Text = styled(Typography.Paragraph)`
  margin-bottom: 0 !important;
`;

const VehicleNote = ({
  vehicleId,
}) => {
  const { data, editViewVehicleRemark, formValue } = useSelector(getVehicleRemark);
  const [note, setNote] = useState(data);
  const remark = formValue || (note ? note.remark : null);

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const onShowEditView = () => {
    dispatch(openEditVehicleRemark());
  };

  const onExitEditView = () => {
    dispatch(closeEditVehicleRemark());
  };

  useEffect(() => {
    setNote(data);
  }, [data]);

  const onChange = (value) => {
    dispatch(editVehicleRemarkForm(value));
  };

  const getFormattedRemark = (value) => {
    if (value) {
      return value.trim();
    }
    if (value === undefined) {
      return null;
    }
    return value;
  };

  const editNote = (values) => {
    const newRemark = getFormattedRemark(values.remark);
    if (newRemark === '') {
      dispatch(submitRemoveVehicleRemark(vehicleId)).then((response) => {
        if (isSuccess(response)) {
          dispatch(closeEditVehicleRemark());
        }
      });
    }
    if (!note && newRemark) {
      dispatch(submitAddVehicleRemark(
        { selectedVehicle: vehicleId, remark: newRemark },
      )).then((response) => {
        if (isSuccess(response)) {
          dispatch(closeEditVehicleRemark());
        }
      });
    }
    if (newRemark && note) {
      dispatch(submitEditVehicleRemark(
        { selectedVehicle: vehicleId, remark: newRemark, note },
      )).then((response) => {
        if (isSuccess(response)) {
          dispatch(closeEditVehicleRemark());
        }
      });
    }
    dispatch(closeEditVehicleRemark());
  };

  return !editViewVehicleRemark ? (
    <Row>
      <Col xs={22}>
        <Text>
          {remark || t('COMMON.MISSING')}
        </Text>
      </Col>
      <Col xs={2}>
        <Tooltip
          placement="leftBottom"
          title={t('ORDER.EDIT_TOOLTIP')}
          open={false}
        >
          <Button
            type="link"
            icon={<EditOutlined />}
            onClick={onShowEditView}
          />
        </Tooltip>
      </Col>
    </Row>
  ) : (
    <Row gutter={[32, 0]}>
      <Form layout="inline" onFinish={(values) => editNote(values)}>
        <Col xs={24}>
          <Form.Item
            style={{ margin: '0px', width: '100%' }}
            name="remark"
            rules={[
              {
                type: 'string', min: 2, max: 100, message: t('COMMON.VALIDATION.BETWEEN_2_100'),
              }]}
          >
            <Input.TextArea
              rows={3}
              placeholder={t('PROPERTIES.ENTER_HERE')}
              defaultValue={remark}
              onChange={(e) => {
                onChange(e.target.value);
              }}
            />
          </Form.Item>
        </Col>
        <Col xs={2}>
          <Form.Item>
            <Button type="link" icon={<SuccessCheckOutlined />} size="small" htmlType="submit" />
          </Form.Item>
        </Col>
        <Col xs={2}>
          <Form.Item>
            <Button
              type="link"
              icon={<CancelCloseOutlined />}
              size="small"
              onClick={onExitEditView}
            />
          </Form.Item>
        </Col>
      </Form>
    </Row>
  );
};

VehicleNote.propTypes = {
  vehicleId: PropTypes.string.isRequired,
};

VehicleNote.defaultProps = {
};

export default VehicleNote;
