import PropTypes from 'prop-types';
import MomentPropTypes from 'react-moment-proptypes';
import { orderCompanyPropTypes } from './company.type';
import { stopsPropTypes } from './stop.type';

export const OrderContext = {
  IN: 'in',
  OUT: 'out',
  UNKNOWN: 'unknown',
};

export const OrderType = {
  TIME: 'time',
  TOUR: 'tour',
  UNKNOWN: 'unknown',
};

export const EditionMode = {
  SHIPPER: 'SHIPPER',
  CARRIER: 'CARRIER',
  UNKNOWN: 'UNKNOWN',
};

export const orderPropTypes = PropTypes.shape({
  id: PropTypes.string,
  _type: PropTypes.oneOf(Object.values(OrderContext)),
  name: PropTypes.string,
  vehicles: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    licencePlateNumber: PropTypes.string,
    trackingTimeframe: PropTypes.shape({
      periods: PropTypes.arrayOf(PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string,
      })),
    }),
  })),
  internalId: PropTypes.string,
  shipperCompany: orderCompanyPropTypes,
  carrierCompany: orderCompanyPropTypes,
  personId: PropTypes.string,
  startDate: PropTypes.number,
  endDate: PropTypes.number,
  createdDate: PropTypes.number,
  active: PropTypes.bool,
  statusDate: PropTypes.number,
  system: PropTypes.string,
  user: PropTypes.string,
  stops: stopsPropTypes,
  type: PropTypes.string,
  actualTravelledDistanceInMeters: PropTypes.number,
});

export const ordersResponsePropTypes = PropTypes.shape({
  loading: PropTypes.bool.isRequired,
  majorReload: PropTypes.bool.isRequired,
  lastUpdated: MomentPropTypes.momentObj,
  data: PropTypes.shape({
    totalElements: PropTypes.number,
    tours: PropTypes.arrayOf(orderPropTypes),
  }),
});

export const routePointPropTypes = PropTypes.shape({
  stopId: PropTypes.string,
  actualArrival: PropTypes.shape({
    time: PropTypes.number,
    status: PropTypes.string,
  }),
  estimatedArrival: PropTypes.shape({
    time: PropTypes.number,
    duration: PropTypes.number,
    length: PropTypes.number,
    status: PropTypes.string,
  }),
  loading: PropTypes.bool,
  error: PropTypes.string,
});

const assetRoutePropTypes = PropTypes.shape({
  vehicleId: PropTypes.string,
  firstRoutePoint: routePointPropTypes,
  nextRoutePoint: routePointPropTypes,
});

export const monitoringOrderRoutePointsResponsePropTypes = PropTypes.shape({
  orderId: PropTypes.string,
  assetsRoutes: PropTypes.arrayOf(assetRoutePropTypes),
});

export const ordersFiltersPropTypes = PropTypes.shape({
  table: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
});

export const estimatedEtaPropTypes = PropTypes.shape({
  vehicleId: PropTypes.string.isRequired,
  eta: PropTypes.shape({
    arrival: PropTypes.number.isRequired,
    duration: PropTypes.number.isRequired,
  }).isRequired,
  path: PropTypes.arrayOf(PropTypes.shape({
    lat: PropTypes.number.isRequired,
    lng: PropTypes.number.isRequired,
  })),
});

export const geofencingStopPropTypes = PropTypes.shape({
  stopId: PropTypes.string.isRequired,
  in: PropTypes.number,
  out: PropTypes.number,
});

export const geofencingDataPropTypes = PropTypes.shape({
  vehicles: PropTypes.arrayOf(
    PropTypes.shape({
      vehicleId: PropTypes.string.isRequired,
      stops: PropTypes.arrayOf(
        geofencingStopPropTypes,
      ),
    }),
  ),
});

export const monitoringOrderStopSnapshotPropTypes = PropTypes.shape({
  stopId: PropTypes.string.isRequired,
  arrivalTime: PropTypes.number,
  departureTime: PropTypes.number,
  arrivalStatus: PropTypes.string,
  departureStatus: PropTypes.string,
});

export const monitoringOrderSnapshotPropTypes = PropTypes.shape({
  stopSnapshots: PropTypes.arrayOf(
    monitoringOrderStopSnapshotPropTypes,
  ),
});

export const etaPropTypes = PropTypes.objectOf(PropTypes.oneOfType([
  PropTypes.shape({
    estimated: estimatedEtaPropTypes,
    unestimated: PropTypes.objectOf(PropTypes.shape({
      vehicleId: PropTypes.string.isRequired,
      reasonCode: PropTypes.string.isRequired,
    })),
  }),
  PropTypes.bool,
  PropTypes.string,
]));

export const orderTypePropTypes = PropTypes.oneOf(Object.values(OrderContext));
