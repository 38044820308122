import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button, Card, Empty, Modal, Typography,
} from 'antd';
import styled from 'styled-components';
import { DateTime, FlexColumnLeft, FormSectionDivider } from '../../../../components';
import NOP from '../../../../utils/NOP';
import {
  Description,
  Label,
  StopDetailLineMinor,
  StopsTable,
  Subtitle,
  Title,
  Value,
} from '../../../../components/Styled/Containers/ConfirmationDialog';
import { MonitoringRequestType, requestPropTypes } from '../../../../types/request.type';
import {
  renderRequestPlace,
  renderRequestStopType,
  renderTimeSlot,
} from '../../../../components/Stop/stopRenderer';

export const NoteDescription = styled(Typography.Paragraph)`
  color: ${({ theme }) => theme.color.darkgray3};
  font-size: 12px;
  margin-top: 5px;
`;

const RequestConfirmationDialog = ({
  onSubmit, onClose, open, request, company, requestType, confirmed,
}) => {
  const { t } = useTranslation();
  const [selectedCompanyVehicle, setSelectedCompanyVehicle] = useState({});

  useEffect(() => {
    if (!request) {
      setSelectedCompanyVehicle({});
      return;
    }

    setSelectedCompanyVehicle({
      companyName: company && company.name,
      vehicleLicence: request && request.carrierVehicle.licensePlate,
    });
  }, [request, company]);
  const columns = [
    {
      title: t('REQUEST.STOPS'),
      dataIndex: 'sequence',
      className: 'stops-table_column',
      width: 30,
      render: (sequence) => (
        <StopDetailLineMinor>
          {Number.parseInt(sequence, 10) + 1}
          {'.'}
        </StopDetailLineMinor>
      ),
    }, {
      title: t('REQUEST.PLACE'),
      dataIndex: 'place',
      className: 'stops-table_column',
      render: (_, row) => renderRequestPlace(row),
    }, {
      title: t('REQUEST.TIMESLOT'),
      dataIndex: 'timeslot',
      className: 'stops-table_column',
      render: (_, row) => renderTimeSlot(t, row.timeRanges[0].startDatetime, row.timeRanges[0].endDatetime),
    }, {
      title: t('REQUEST.STOP_TYPE'),
      dataIndex: 'stopType',
      className: 'stops-table_column',
      width: 160,
      render: (_, row) => renderRequestStopType(t, row),
    },
  ];

  return (
    <Modal
      open={open}
      onCancel={onClose}
      width="750px"
      closable={false}
      footer={(
        <>
          <Button type="link" onClick={onClose}>{t('COMMON.CANCEL')}</Button>
          <Button type="primary" disabled={confirmed} onClick={onSubmit}>{t('COMMON.CONFIRM')}</Button>
        </>
      )}
    >
      {request ? (
        <>
          <Title>
            {t('REQUEST.CONFIRMATION.TITLE')}
          </Title>
          <FormSectionDivider />
          <FlexColumnLeft>
            <Description>
              <Label>
                {t('REQUEST.ORDER_NUMBER')}
                :
              </Label>
              <Value>{request.orderNumber}</Value>
            </Description>
            <Description>
              <Label>
                {t('REQUEST.ADDITIONAL_INFO')}
                :
              </Label>
              <Value>{request.additionalInfo}</Value>
            </Description>
            <FormSectionDivider />
            <Description>
              <Label>
                {t('REQUEST.CARRIER')}
                :
              </Label>
              <Value>{selectedCompanyVehicle.companyName}</Value>
            </Description>
            <Description>
              <Label>
                {t('VEHICLE.VEHICLE_LICENSE_PLATE')}
              </Label>
              <Value>{selectedCompanyVehicle.vehicleLicence}</Value>
            </Description>
          </FlexColumnLeft>
          <NoteDescription>{t('REQUEST.CARRIER_NOTE')}</NoteDescription>
          <FormSectionDivider />
          <Subtitle>
            {requestType === MonitoringRequestType.TIME
              ? t('REQUEST.TIME_MONITORING_DETAILS')
              : t('REQUEST.TOUR_MONITORING_DETAILS')}
          </Subtitle>
          <Card align="baseline">
            {requestType === MonitoringRequestType.TIME
              ? (
                <>
                  <Description>
                    <Label>
                      {t('REQUEST.START_DATE')}
                      :
                    </Label>
                    <Value>
                      <DateTime value={request.timeMonitoring.timeRange.startDatetime} />
                    </Value>
                  </Description>
                  <Description>
                    <Label>
                      {t('REQUEST.END_DATE')}
                      :
                    </Label>
                    <Value>
                      <DateTime value={request.timeMonitoring.timeRange.endDatetime} />
                    </Value>
                  </Description>
                </>
              )
              : (
                <StopsTable
                  size="small"
                  columns={columns}
                  dataSource={request.tourMonitoring.tourStops}
                  bordered={false}
                  pagination={false}
                  rowKey="sequence"
                />
              )}
          </Card>
        </>
      ) : <Empty />}
    </Modal>
  );
};

RequestConfirmationDialog.propTypes = {
  onSubmit: PropTypes.func,
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  request: requestPropTypes,
  company: PropTypes.shape({
    id: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  }),
  requestType: PropTypes.string,
  confirmed: PropTypes.bool,
};

RequestConfirmationDialog.defaultProps = {
  onSubmit: NOP,
  request: {
    stops: [],
  },
  company: {},
  requestType: MonitoringRequestType.TIME,
  confirmed: false,
};

export default RequestConfirmationDialog;
