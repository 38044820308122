import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Form, Input, message, Modal, Select, Spin,
} from 'antd';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import AwesomeDebouncePromise from 'awesome-debounce-promise';
import { tableConfigurationType } from '../../types';
import { getCompanies } from '../../store/selectors';
import { stringPropSorter } from '../../utils/comparators';
import { companiesFormToConfiguration, toCompanyValueLabel } from '../../types/mappers';
import { clearCompaniesSearch, fetchCompanies } from '../../store/actions';
import { isSuccess } from '../../utils/responseUtils';
import FilterCreationSuccessContent from './FilterCreationSuccessContent';

const CompanyFilterModal = ({
  open, configuration, onOk, onCancel, onConfigurationSelected,
}) => {
  const { t } = useTranslation();
  const [form] = Form.useForm();
  const { companies, loading: companiesLoading } = useSelector(getCompanies);
  const [companiesToShow, setCompaniesToShow] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (companies) {
      setCompaniesToShow(companies.map(({ id, name }) => ({ id, name })).sort(stringPropSorter('name')));
    }
  }, [companies]);

  useEffect(() => {
    if (form) {
      form.resetFields();
    }
    if (configuration) {
      form.setFields([
        { name: 'id', value: configuration.id },
        { name: 'name', value: configuration.name },
        { name: 'companyIds', value: toCompanyValueLabel(configuration.filters) },
      ]);
    }
  }, [form, configuration]);

  const searchAPIDebounced = AwesomeDebouncePromise((q) => {
    if (q && q.length >= 3) {
      dispatch(fetchCompanies(q));
    }
  }, 500);

  const searchCompanies = (val) => {
    searchAPIDebounced(val);
  };

  const closeModal = () => {
    dispatch(clearCompaniesSearch());
    onCancel();
  };

  const handleResult = (result) => {
    if (isSuccess(result)) {
      const { name } = result.payload.data;
      message.success(
        <FilterCreationSuccessContent
          name={name}
          onSelect={onConfigurationSelected}
        />,
      );
      closeModal();
    }
  };

  return (
    <Modal
      title={configuration
        ? t('PERSONALIZATION.EDIT_COMPANIES_CONFIGURATION')
        : t('PERSONALIZATION.CREATE_COMPANIES_CONFIGURATION')}
      open={open}
      okText={t('COMMON.SAVE')}
      onOk={() => form
        .validateFields()
        .then((values) => onOk(companiesFormToConfiguration(values)))
        .then((result) => handleResult(result))
        .catch()}
      onCancel={closeModal}
      maskClosable={false}
    >
      <Form
        form={form}
        layout="vertical"
        name="companiesFilter"
      >
        <Form.Item name="id" hidden>
          <Input />
        </Form.Item>
        <Form.Item
          name="name"
          label={t('PERSONALIZATION.CONFIGURATION_NAME')}
          rules={[{
            type: 'string',
            min: 1,
            max: 25,
            message: t('PERSONALIZATION.NAME_SIZE_RESTRICTION'),
          }, {
            required: true,
            message: t('PERSONALIZATION.PLEASE_PROVIDE_NAME'),
          }]}
        >
          <Input placeholder={t('PERSONALIZATION.TYPE_IN_CONFIGURATION_NAME')} />
        </Form.Item>
        <Form.Item
          name="companyIds"
          label={t('PERSONALIZATION.COMPANIES')}
          rules={[{
            type: 'array',
            min: 1,
            required: true,
            message: t('PERSONALIZATION.PLEASE_SELECT_COMPANY'),
          }]}
        >
          <Select
            notFoundContent={companiesLoading ? <Spin size="small" /> : t('PERSONALIZATION.NO_COMPANIES')}
            filterOption={false}
            onSearch={searchCompanies}
            style={{ width: '100%' }}
            mode="multiple"
            labelInValue
            dropdownMatchSelectWidth={false}
            showSearch
            placeholder={t('PERSONALIZATION.SELECT_COMPANIES_PROMPT')}
          >
            {companiesToShow.map(({ id, name }) => (
              <Select.Option key={id} value={id}>{name}</Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

CompanyFilterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onOk: PropTypes.func.isRequired,
  onConfigurationSelected: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  configuration: tableConfigurationType,
};

CompanyFilterModal.defaultProps = {
  configuration: null,
};

export default CompanyFilterModal;
